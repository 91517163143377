import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Martin Jones | Web Developer',
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Martin Jones | Web Developer',
};

// HERO DATA
export const heroData = {
  title: 'Hi, my name is',
  name: 'Martin Jones',
  subtitle: 'Web Developer',
  cta: 'Find out more',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile.jpg',
  paragraphOne:
    'Hello! I’m Martin Jones. I’m an experienced developer with a strong technical background in I.T. and a genuine interest for all things web.',
  paragraphTwo:
    'Experienced in Front-end and Back-end technologies, and all stages of the development process including information gathering, planning, design, development, testing, delivery, and maintenance. Familiar with working within an Agile development environment. I have a passion for working with the latest technology, at the highest standard.',
  paragraphThree: '',
  resume: 'Martin_Jones_CV.pdf', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'placeup.jpg',
    title: 'Place Sharing App',
    info: 'Place sharing app project, built with ReactJS, Node, Express, & MongoDB',
    info2: '',
    url: 'https://places-app-35d1e.web.app',
    repo: 'https://github.com/emjayy/places-app', // if no repo, the button will not show up
    tech: [
      {
        id: nanoid(),
        title: 'React',
        img: '/react.svg',
      },
      {
        id: nanoid(),
        title: 'Node',
        img: '/nodejs.svg',
      },
      {
        id: nanoid(),
        title: 'ExpressJS',
        img: '/express-js.svg',
        classname: 'width-80',
      },
      {
        id: nanoid(),
        title: '',
        img: '/mongodb.svg',
        classname: 'width-90',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'food-app.jpg',
    title: 'Food Order App',
    info: 'Food ordering app project frontend, built with ReactJS & Redux',
    info2: '',
    url: 'https://food-app-67a27.web.app',
    repo: 'https://github.com/emjayy/react-food-app', // if no repo, the button will not show up
    tech: [
      {
        id: nanoid(),
        title: 'React',
        img: '/react.svg',
      },
      {
        id: nanoid(),
        title: 'Redux',
        img: '/redux.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'sideline.jpg',
    title: 'Live Sport Video Streaming Platform',
    info: 'Front-end development using ReactJS, back-end development using Node & Express, while working at Born Digital',
    info2: '',
    url: 'https://sideline.live',
    repo: '', // if no repo, the button will not show up
    tech: [
      {
        id: nanoid(),
        title: 'React',
        img: '/react.svg',
      },
      {
        id: nanoid(),
        title: 'Node',
        img: '/nodejs.svg',
      },
      {
        id: nanoid(),
        title: 'ExpressJS',
        img: '/express-js.svg',
        classname: 'width-80',
      },
      {
        id: nanoid(),
        title: 'Azure',
        img: '/azure.svg',
      },
    ],
  },
  // {
  //   id: nanoid(),
  //   img: 'tfs.jpg',
  //   title: 'Fleet Management App',
  //   info: 'Front-end development using React.js, Redux, Material UI',
  //   info2: '',
  //   url: '',
  //   repo: '', // if no repo, the button will not show up
  //   tech: [
  //     {
  //       id: nanoid(),
  //       title: 'React',
  //       img: '/react.svg',
  //     },
  //     {
  //       id: nanoid(),
  //       title: 'Redux',
  //       img: '/redux.svg',
  //     },
  //     {
  //       id: nanoid(),
  //       title: 'Material UI',
  //       img: '/material-ui.svg',
  //     },
  //     {
  //       id: nanoid(),
  //       title: 'Azure',
  //       img: '/azure.svg',
  //     },
  //   ],
  // },
  {
    id: nanoid(),
    img: 'safetywise.jpg',
    title: 'Health & Safety App',
    info: 'Front-end development using ReactJS, Redux, Material UI, while working at Born Digital',
    info2: '',
    url: '',
    repo: '', // if no repo, the button will not show up
    tech: [
      {
        id: nanoid(),
        title: 'React',
        img: '/react.svg',
      },
      {
        id: nanoid(),
        title: 'Redux',
        img: '/redux.svg',
      },
      {
        id: nanoid(),
        title: 'Material UI',
        img: '/material-ui.svg',
      },
      {
        id: nanoid(),
        title: 'Azure',
        img: '/azure.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'resbook.jpg',
    title: 'ResBook',
    info: 'Front-end development using ReactJS & Redux for Calendar & Onboarding Wizard features, while working at Tomahawk Tourism',
    info2: '',
    url: 'https://www.resbook.com',
    repo: '', // if no repo, the button will not show up
    tech: [
      {
        id: nanoid(),
        title: 'React',
        img: '/react.svg',
      },
      {
        id: nanoid(),
        title: 'Redux',
        img: '/redux.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'pharmapac.jpg',
    title: 'Pharmapac Umbraco Website',
    info: 'Umbraco ASP.NET MVC CMS development and front-end integration, while working at Born Digital',
    info2: '',
    url: 'https://www.pharmapac.co.nz',
    repo: '', // if no repo, the button will not show up
    tech: [
      {
        id: nanoid(),
        title: 'Umbraco',
        img: '/umbraco.svg',
      },
      {
        id: nanoid(),
        title: '.NET',
        img: '/net.svg',
      },
    ],
  },
  {
    id: nanoid(),
    img: 'tribe.jpg',
    title: 'Tribe Umbraco Website',
    info: 'Umbraco ASP.NET MVC CMS development and front-end integration, while working at Born Digital',
    info2: '',
    url: 'https://www.tribegroup.com',
    repo: '', // if no repo, the button will not show up
    tech: [
      {
        id: nanoid(),
        title: 'Umbraco',
        img: '/umbraco.svg',
      },
      {
        id: nanoid(),
        title: '.NET',
        img: '/net.svg',
      },
    ],
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'martinjones@windowslive.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/martinrobertjones1',
    },
  ],
};
